html,
body,
#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.year {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.month {
  margin: 5px 5px 15px 5px;
}

.month-name {
  color: #a9a8a8;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

.day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.date {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #014a62;
  color: white;
}
/* 
.date.in-month:hover > button {
  color: white;
} */

.today {
  background: #014a62;
  color: white;
}

/* .today > button {
  color: white;
} */

.date.prev-month,
.date.next-month {
  color: grey;
}
